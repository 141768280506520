import Image from 'next/image';
import Link from 'components/common/CustomLink';
import HouseImage from 'public/images/404-graphic.png';
import React from 'react';

export default function FourOhFour() {
  return (
    <div className="mx-auto h-screen flex flex-col text-center items-center justify-center align-middle">
      <Image
        src={HouseImage}
        alt="Old House"
        width={200}
        height={300}
        layout="fixed"
      />
      <h1 className="pt-7 text-6xl font-semibold">404</h1>
      <p className="max-w-xl p-7">
        A variety of Dwellsy products are temporarily unavailable. If you have
        reached this page and have questions about your account, email us at{' '}
        <Link href="mailto:help@dwellsy.com">help@dwellsy.com</Link> and we can
        resolve it manually.
      </p>
    </div>
  );
}
